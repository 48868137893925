import { Buffer } from "buffer";
import axios from "axios";
import Cookies from "universal-cookie";

const getBlogsByCategory = async (tag, limit, setShowError, showError) => {
    let tagStr = Buffer.from(tag).toString("base64");

    // const searchURL = `${process.env.NEXT_PUBLIC_MISC_URL}/blogs/search?searchQuery=${querymatter}`;
    const response = await fetch(
        process.env.REACT_APP_MISCELLANEOUS_URL +
            `/blogs/categories?tags=${tagStr}&page=1&limit=${limit}`
    ).catch((e) => {
        setShowError(true);
    });
    if (!showError) {
        const data = await response.json();
        return data.blogs;
    }
};
const getRecentBlogs = async (setShowError, showError) => {
    const response = await fetch(process.env.REACT_APP_MISCELLANEOUS_URL + `/getRecentBlogs`).catch(
        (e) => {
            setShowError(true);
        }
    );
    if (!showError) {
        const data = await response.json();
        return data.recentBlogs;
    }
};

const searchBlogs = async (searchTerm, setSearchResult) => {
    const response = await fetch(
        process.env.REACT_APP_MISCELLANEOUS_URL + `/blogs/search?searchQuery=${searchTerm}`
    ).catch((e) => {
        // setShowError(true);
    });
    const data = await response.json();
    setSearchResult(data.blogs);
};

const getBlogById = (
    setmostViewedBlogDetails,
    id,
    setBlogPageDetailsTag,
    setCount,
    setLiked,
    setIsLoading
) => {
    const cookies = new Cookies();
    axios
        .get(process.env.REACT_APP_MISCELLANEOUS_URL + `/blogs/${id}`, {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            setmostViewedBlogDetails(response.data.blog[0]);
            setBlogPageDetailsTag(response.data.blog[0].tags);
            setCount(response.data.blog[0].likes);
            setLiked(response.data.blog[0].user_liked);
            setIsLoading(false);
        })
        .catch((e) => {
            // setShowError(true);
        });
};

export { getBlogsByCategory, searchBlogs, getBlogById, getRecentBlogs };
