import React from "react";
import HeaderCoursesDropDown from "../HeaderCoursesDropDown/HeaderCoursesDropDown.js";
import RenaissanceHoverBox from "../Renaissance/RenaissanceHoverBox.js"
import CertificationHoverBox from "../CourseCertification/CertificationHoverBox.js"
import FreeLearningHoverBox from "../HeaderResources/FreeLearningHoverBox.js"
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const NavLinks = (props) => {
    return (
        <div className="header-nav-links-container">
            <HeaderCoursesDropDown className="new-header-nav-link"/>
            <RenaissanceHoverBox className="new-header-nav-link"/>
            <CertificationHoverBox className="new-header-nav-link"/>
            <FreeLearningHoverBox className="new-header-nav-link"/>
            <div className="nav-backdrop">
            </div>
            {/* <HeaderCoursesDropDown />

            <a
                className={`${GTM_TRACKER.SUCCESS_STORIES} header-nav-link`}
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}crack-coding-interviews/stories`}
            >
                Success Stories
            </a>

            <a
                className={`${GTM_TRACKER.HIRE_FROM_US} header-nav-link`}
                target="_blank"
                rel="noreferrer"
                href="https://programmingpathshala.com/hire-from-us#"
            >
                Hire From Us
            </a>

            <a
                className={`${GTM_TRACKER.CAMPUS_PROGRAM} header-nav-link`}
                target="_blank"
                rel="noreferrer"
                href="https://programmingpathshala.com/campus-program#"
            >
                Campus Program
            </a>

            <a
                className={`${GTM_TRACKER.BLOGS} header-nav-link`}
                target="_blank"
                rel="noreferrer"
                href="https://blogs.programmingpathshala.com/"
            >
                Blogs
            </a>

            <a
                className={`${GTM_TRACKER.EVENTS} header-nav-link`}
                target="_blank"
                rel="noreferrer"
                href="https://events.programmingpathshala.com/"
            >
                Events
            </a> */}
        </div>
    );
};

export default NavLinks;
