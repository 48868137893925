import * as React from "react";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getTagsList } from "./Adminclient";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            autoWidth: true,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function AddBlogTagsDropDown(props) {
    const [tagList, setTagList] = useState([]);
    const theme = useTheme();
    useEffect(() => {
        getTagsList(setTagList);
    }, []);

    const getTags = () => {
        let tags = [];

        for (let i = 0; i < tagList.length; i++) {
            tags.push(tagList[i].tag_name);
        }
        return tags;
    };
    const names = getTags();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        props.setTagName(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <div>
            <FormControl sx={{ width: "90%" }}>
                <InputLabel id="demo-multiple-chip-label">Select Tag</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={props.tagName}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="select-tag" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, props.tagName, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
