import { getCurrentCourse } from "../CourseUtility";
import { getCurrentCourseIsSubscribedResponseIdentifier } from "../CourseUtility";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import { initiateFreeTrialForExistingUsers } from "../../components/StartFreeTrial/StartFreeTrialClient";
import redirectToLandingPageStrategy from "./LandingPageRedirectionStrategy";
import CryptoJS from "crypto-js";

const redirectionStrategy = (redirectUser) => {
    redirectUser(`/course-select`);
};

const startFreeTrial = async (isFormFilled, redirectUser) => {
    redirectUser("/start-free-trial");
};

const goToDashboard = async () => {
    window.open(`${process.env.REACT_APP_NEW_DASHBOARD_URL}/${getCurrentCourse()}`, "_self");
};

const checkCurrentCourseAndSubscriptionStatus = async (
    response,
    redirectUser,
    setStatesAfterIsSubscribed,
    redirect_url
) => {
    //User is logged in from the main page, takes him to course select screen
    if (localStorage.getItem("isLoggedInFromMainPage")) {
        redirectionStrategy(redirectUser);
        setStatesAfterIsSubscribed(response);
        localStorage.removeItem("isLoggedInFromMainPage");
        return;
    }

    //logged in from one of course landing pages(ren,lld,react,dsa-crash)
    const course = getCurrentCourse();
    if (isNULLEMPTYORUNDEFINED(course)) {
        //course is null takes him back to landing page
        redirectToLandingPageStrategy();
    } else {
        //valid course is present in local storage, the user comes from one of the course landing pages, check the isSubscribed response
        const courseIsSubscribedResponseIdentifier =
            getCurrentCourseIsSubscribedResponseIdentifier();
        if (response && response.data && response.data[courseIsSubscribedResponseIdentifier]) {
            //subscription  for this course is already present there takes to dashboard
            if (!isNULLEMPTYORUNDEFINED(redirect_url)) {
                var replaced = redirect_url.split(" ").join("+");
                var decryptedUrl = CryptoJS.AES.decrypt(
                    replaced,
                    process.env.REACT_APP_REDIRECTION_PASSPHRASE
                );
                window.open(decryptedUrl.toString(CryptoJS.enc.Utf8), "_self");
            } else goToDashboard();
        } else {
            //no subscription initiate free trial and then take to dashboard
            redirectUser("/start-free-trial");
        }
    }
};

export {
    redirectionStrategy,
    checkCurrentCourseAndSubscriptionStatus,
    goToDashboard,
    startFreeTrial,
};
