import axios from "axios";
import Cookies from "universal-cookie";
import { Mutex } from "async-mutex";
import { isTokenExpired, removeCookiesAndLogout, setFreshTokens } from "./SessionUtility";

// CONSTANTS
const cookies = new Cookies();
const mutex = new Mutex();
const axiosInstance = axios.create();

// Interceptor : gets called before every request
axiosInstance.interceptors.request.use(
    async (request) => {
        // If token is not expired, do nothing and proceed with this request
        if (!isTokenExpired(cookies.get("authorization"))) return request;

        // START : Critical Section
        // acquire returns an (ES6) promise that will resolve as soon as the mutex is available
        const release = await mutex.acquire();
        try {
            await setFreshTokens(request);
        } catch (err) {
            console.error("Error refreshing token : " + err);
            removeCookiesAndLogout();
        } finally {
            release();
            return request;
        }
        // END : Critical Secion
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosInstance;
