import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getAuthorList } from "./Adminclient";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            autoWidth: true,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const AddAuthorDropDown = (props) => {
    const [authorList, setAuthorList] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        getAuthorList(setAuthorList);
    }, []);

    const getAuthor = () => {
        let authors = [];

        for (let i = 0; i < authorList.length; i++) {
            authors.push(authorList[i].name);
        }
        return authors;
    };
    const names = getAuthor();

    const handleChange = (event) => {
        const blogAuthorName = event.target.value;
        props.setAuthorName(blogAuthorName);
    };
    return (
        <div>
            <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-chip-label">Select Author</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    value={props.authorName}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="select-author" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            <Chip key={selected} label={selected} />
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, names, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default AddAuthorDropDown;
