import { gaCourseNameMap } from "../../courses/CourseConstants";
const coursesDetails = [
    {
        courseName: "Renaissance",
        courseDescription:
            "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",

        courseLink: "/crack-coding-interviews",
        clickable: true,
        showNewIcon: false,
        gaCourseTracker: gaCourseNameMap.renaissance,
    },
    {
        courseName: "Low Level Design for Professionals",
        courseDescription:
            "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
        courseLink: "/low-level-design",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap["low-level-design"],
    },
    {
        courseName: "Learn Backend Engineering in JAVA Spring Boot Framework",
        courseDescription:
            "Master Spring MVC, Spring Security, JPA, Hibernate, and more. Explore design patterns, concurrency, and excel in backend developer interviews.",
        courseLink: "/java-springboot",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap["java-springboot"],
    },
    {
        courseName: "Data Structures & Algorithms Essentials",
        courseDescription:
            "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
        courseLink: "/dsa-and-algorithms-essentials",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap["crash-course"],
    },
    {
        courseName: "Become a Brilliant Front-End Engineer",
        courseDescription:
            "Master the skills of a front-end engineer and build modern & responsive website applications.",

        courseLink: "/become-a-frontend-engineer",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.react,
    },
    {
        courseName: "Begin with Programming in C++",
        courseLink: "/begin-with-c++",
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.cpp,
    },
    {
        courseName: "Python Full Course",
        courseDescription:
            "Become a Python pro and unlock the door to endless career opportunities",
        courseLink: "/begin-with-python",
        clickable: true,
        showNewIcon: true,
        gaCourseTracker: gaCourseNameMap.python,
    },
];

const courses = {
    RENAISSANCE: "Renaissance",
    LLD: "Low Level Design for Professionals",
    CPP: "begin with c++",
    PYTHON: "Python full course",
    // REACT: "Become a brilliant Front-End Engineer",
};
export { coursesDetails, courses };
