const { availableCourses, COURSE_RESPONSE_IDENTIFIERS } = require("./CourseConstants");

const isNULLEMPTYORUNDEFINED = (value) => {
    return value === null || value === undefined || value === "";
};

const inValidCourse = (course)=>{
    return isNULLEMPTYORUNDEFINED(course) || !Object.values(availableCourses).includes(course);
}

const getCurrentCourse = () => {
    const currentCourse = localStorage.getItem("course");
    if(!inValidCourse(currentCourse)){
        return currentCourse;
    }
};


const setCurrentCourse = (course) => {
    //while user is on one of the course landing pages(ren,lld,react,dsa-crash), then isLoggedInFromMainPage flag in ls is cleared.
    localStorage.removeItem("isLoggedInFromMainPage");
    if(!inValidCourse(course)){
        localStorage.setItem("course", course);
    }
};

const getCurrentCourseIsSubscribedResponseIdentifier = () => {
    const currentCourse = getCurrentCourse();
    if(!inValidCourse(currentCourse)){
        return COURSE_RESPONSE_IDENTIFIERS[currentCourse];
    } 
};

const isUserOnMultipleCourses = (isSubscribedResponse) => {
    if (isNULLEMPTYORUNDEFINED(isSubscribedResponse)) return false;
    return Object.keys(isSubscribedResponse).length > 1;
};

const isUserOnRenaissanceCourse = () => {
    return getCurrentCourse() === availableCourses.RENAISSANCE;
};

const hasFilledUserDetailsForm = (isSubscribedResponse) => {
    let isFormFilled = false;
    Object.keys(isSubscribedResponse).forEach((courseIdentifier) => {
        const courseSubscriptionDetails = isSubscribedResponse[courseIdentifier].Subscription;
        if (courseSubscriptionDetails.everSubscribed === true) isFormFilled = true;
    });
    return isFormFilled;
};

module.exports = {
    getCurrentCourse,
    setCurrentCourse,
    getCurrentCourseIsSubscribedResponseIdentifier,
    isUserOnMultipleCourses,
    isUserOnRenaissanceCourse,
    hasFilledUserDetailsForm,
    inValidCourse
};
