import { useState, useEffect } from "react";
const TableOfContent = () => {
    const [headings, setHeadings] = useState([]);
    const [selectedHeading, setSelectedHeading] = useState();

    const handleContentLoaded = () => {
        Array.from(document.querySelectorAll("h1,h2,h3")).map((elem, index) => {
            elem.id = `subHeading${index}`;
        });

        const elements = Array.from(document.querySelectorAll("h1,h2,h3")).map((elem) => ({
            id: elem.id,
            text: elem.innerText,
            level: Number(elem.nodeName.charAt(1)),
        }));
        setHeadings(elements);
    };

    useEffect(() => {
        if (headings.length === 0) {
            handleContentLoaded();
        }
    }, [headings]);

    return (
        <>
            {headings.length === 0 ? (
                <></>
            ) : (
                <div>
                    <p className="font-PoppinsMedium text-[1.1vw] text-[#191D23]">
                        Table of Content
                    </p>
                    <nav className="pt-[0.5vw]">
                        {/* Add the scrollable-toc class here */}
                        <ul className="list-disc scrollable-toc">
                            {headings.map((heading) => (
                                <li
                                    key={heading.id}
                                    className={
                                        selectedHeading === heading.id
                                            ? "text-[black] text-[1vw] py-[0.2vw]"
                                            : "text-[#64748B] text-[1vw] py-[0.2vw] hover:text-[black]"
                                    }
                                >
                                    <a
                                        href={`#${heading.id}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedHeading(heading.id);
                                            var headerEl = document.getElementById("header");
                                            var headerHeight = headerEl.offsetHeight;
                                            var buffer = 50;
                                            var topOfElement =
                                                document.querySelector(`#${heading.id}`).offsetTop -
                                                headerHeight -
                                                buffer;
                                            window.scroll({
                                                top: topOfElement,
                                                behavior: "smooth",
                                            });
                                        }}
                                    >
                                        {heading.text}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            )}
        </>
    );
};

export default TableOfContent;