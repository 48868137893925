const SET_PASSWORD_MODES = {
    SIGNUP: "SIGNUP",
    RESET_PASSWORD: "RESET_PASSWORD",
};

const HEADERS = {
    "Cache-control": "no-store",
};

const GOOGLE_SIGNUP_STEPS = {
    SET_PASSWORD: "SET_PASSWORD",
    START_FREE_TRIAL: "START_FREE_TRIAL",
};

const SIGNUP_AND_LOGIN_PAGE_STEPS = {
    LOGIN: "LOGIN",
    SIGNUP: "SIGNUP",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
    START_FREE_TRIAL: "START_FREE_TRIAL",
};

const FORGOT_PASSWORD_SCREENS = {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    CREATE_NEW_PASSWORD: "CREATE_NEW_PASSWORD",
};

const CURRENT_AUTH_FLOW = {
    LOGIN: "LOGIN",
    SIGNUP: "SIGN_UP",
    PASSWORD_RESET: "PASSWORD_RESET",
};
const COURSES = {
    RENAISSANCE: 1,
    LLD: 2,
};

const LOGGED_IN_FROM = {
	MAIN_PPA: "ppa"
}

const SUCCESS = "Success";
const HTTP_SUCCESS = 200;

const PHONE_NUMBER_VALIDATION_REGEX =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// const YEAR_VALIDATION_REGEX = /^[12][0-9]{3}$/;
//^ and $ are the beginning and end of the string anchors ,| is used to denote alternates, e.g. this|that
const YEAR_VALIDATION_REGEX = /^$|/;

const REDIRECT_URL_FOR_GOOGLE_AUTH = "http://localhost:3000";
const COGNITO_CLIENT_ID = "3r2p1ic9qdodqgl2tb4rfpvf5k";
const GOOGLE_OAUTH_LOGIN_URL = `https://programmingpathshala.auth.ap-south-1.amazoncognito.com/oauth2/authorize?redirect_uri=${REDIRECT_URL_FOR_GOOGLE_AUTH}&response_type=token&client_id=${COGNITO_CLIENT_ID}&identity_provider=Google`;

const ONE_YEAR_IN_SECONDS = 31536000;
const COOKIE_MAX_AGE = ONE_YEAR_IN_SECONDS;

export {
    SET_PASSWORD_MODES,
    HEADERS,
    HTTP_SUCCESS,
    SIGNUP_AND_LOGIN_PAGE_STEPS,
    GOOGLE_SIGNUP_STEPS,
    FORGOT_PASSWORD_SCREENS,
    CURRENT_AUTH_FLOW,
    PHONE_NUMBER_VALIDATION_REGEX,
    YEAR_VALIDATION_REGEX,
    GOOGLE_OAUTH_LOGIN_URL,
    COOKIE_MAX_AGE,
	COURSES,
	LOGGED_IN_FROM,
	SUCCESS
};
