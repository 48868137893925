import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const GlobalContext = React.createContext();

export function useGlobalContext() {
    return useContext(GlobalContext);
}

export function GlobalContextProvider({ children }) {
    const [currentLoggedInUserName] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [showError, setShowError] = useState(false);
    const [query, setQuery] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const checkSubscribed = () => {
        const cookies = new Cookies();

        axios
            .get(process.env.REACT_APP_UTILITY_URL + "/isSubscribed", {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            })
            .then((response) => {
                if (
                    response.data.Response.Subscription.status &&
                    !response.data.Response.Subscription.onTrial
                ) {
                    setLoggedIn(true);
                }
                if (
                    response.data.Response &&
                    response.data.Response.Subscription &&
                    response.data.Response.Subscription.status &&
                    response.data.Response.Subscription.onTrial
                ) {
                    setLoggedIn(true);
                } else {
                    setLoggedIn(true);
                }
            })
            .catch((error) => {});
    };

    const getIsUserAdmin = () => {
        const cookies = new Cookies();
        axios
            .get(process.env.REACT_APP_UTILITY_URL + "/isAdmin", {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            })
            .then((response) => {
                setIsAdmin(response.data.Response.is_admin);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const initiateLogout = () => {
        const cookies = new Cookies();
        if (cookies.get("id_token"))
            cookies.remove("id_token", {
                path: "/",
                domain: ".programmingpathshala.com",
            });
        if (cookies.get("access_token"))
            cookies.remove("access_token", {
                path: "/",
                domain: ".programmingpathshala.com",
            });
        if (cookies.get("authorization"))
            cookies.remove("authorization", {
                path: "/",
                domain: ".programmingpathshala.com",
            });

        window.open(process.env.REACT_APP_FRONTEND_MAIN_URL, "_self");
    };

    useEffect(() => {
        checkSubscribed();
        getIsUserAdmin();
    }, []);

    const value = {
        loggedIn,
        currentLoggedInUserName,
        isAdmin,
        setIsAdmin,
        initiateLogout,
        query,
        setQuery,
        showError,
        setShowError,
        getIsUserAdmin,
    };
    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
}
