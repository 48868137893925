import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ExploreBlogs.css";
import arrowPrev from "../../resources/images/left-arrow-blue.png";
import arrowNext from "../../resources/images/right-arrow-blue.png";
import ellipse from "../../resources/images/Ellipse.svg";
import { BlogContents } from "./ExploreBlogsCardDetails";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const ExploreBlogs = (props) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    const getHeading = () => {
        return (
            <div className="font-PoppinsSemiBold text-[2.5vw] text-center pt-[7vw] pb-[2vw] max-sm:text-[4.5vw]">
                Explore Blogs
            </div>
        );
    };
    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {BlogContents.blogCardDetails &&
                    BlogContents.blogCardDetails.map((data, index) => (
                        <a
                            href={data.blogLink}
                            className="!w-[90%] bg-white border border-[#d9d9d9] rounded-[1.3vw] overflow-hidden relative cursor-pointer  h-[26vw] max-sm:h-[70vw] max-sm:rounded-[3.6vw]"
                            key={index}
                        >
                            <div className="w-full h-[15vw] mb-[1vw] max-sm:h-[40vw]">
                                <img className="w-full h-full object-cover" src={data.img} alt="" />
                            </div>
                            <div className="flex items-center p-[1vw] max-sm:p-[2vw]">
                                <p className="text-[#231f20] text-[1.1vw] mr-[0.5vw] max-sm:text-[3vw] max-sm:mr-[2vw]">
                                    {data.date}
                                </p>
                                <div className="w-[1.5%] mr-[0.5vw] max-sm:mr-[2vw]">
                                    <img className="w-full" src={ellipse} alt="" />
                                </div>
                                <p className="text-[#231f20] text-[1.1vw] max-sm:text-[3vw]">
                                    {data.time}
                                </p>
                            </div>
                            <p className="text-[1.4vw] font-PoppinsMedium px-[1vw] max-sm:text-[4vw]">
                                {data.desc}
                            </p>
                        </a>
                    ))}
            </Slider>
        );
    };

    return (
        <>
            <div className="blogs-wrapper">
                {getHeading()}
                {getSlidingStories()}
            </div>
        </>
    );
};

export default ExploreBlogs;
