import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontSize: "18px",
  },
});
const style = {
  color: "white",
  background:
    "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
  fontFamily: "Poppins-SemiBold",
  textTransform: "capitalize",
  padding: { sm: "1vw 20px", md: ".65vw 5.6vw" },
  fontSize: "15px",
  whiteSpace: "noWrap",
};
const courseIncludesDropdownStyles = [
  {
    backgoundColor: "#F7FAFC",
  },
];

export { theme, style, courseIncludesDropdownStyles };
