const availableCourses = {
    RENAISSANCE: "renaissance",
    LLD: "low-level-design",
    CPP: "cpp",
    REACT: "react",
    CRASH_COURSE: "crash-course",
    PYTHON: "python",
};

export { availableCourses };
