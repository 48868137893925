import { useEffect } from "react";
import Linkify from "react-linkify";
import "./BlogContent.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import TableOfContent from "./TableOfContent";
import UpgradeCareer from "./UpgradeCareer";
import facebookIcon from "../../resources/images/facebook.svg";
import linkedinIcon from "../../resources/images/linkedin.svg";
import twitterIcon from "../../resources/images/twitter.svg";
import whatsappIcon from "../../resources/images/share.svg";
import editPencil from "../../resources/images/pencil.svg";
import {
    WhatsappShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import { ShareMessageContent } from "../../constants/constants";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";

const BlogContent = ({
    banner_image_url,
    content,
    author,
    date_updated,
    read_time_min,
    title,
    author_avatar_url,
    wrapper_class,
    youtubeLink,
    blog_url,
    blog_id,
}) => {
    const navigate = useNavigate();
    const { windowWidth } = useWindowDimensions();

    const { isAdmin } = useGlobalContext();

    const BLOG_URL = `https://blogs.programmingpathshala.com/${blog_url}/${blog_id}`;

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [blog_id]);

    const getBlogPageImgBanner = () => {
        return (
            <div className="w-full max-sm:w-auto">
                <img src={banner_image_url} />
            </div>
        );
    };

    const getParsedContents = () => {
        return (
            <div>
                <Linkify>
                    <div
                        className="blog-detail-page-contents"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                </Linkify>
            </div>
        );
    };
    const getEmbedReadyYTLink = (link) => {
        if (link.indexOf("youtube") >= 0 || link.indexOf("youtu.be") >= 0) {
            link = link.replace("watch?v=", "embed/");
            link = link.replace("/watch/", "/embed/");
            link = link.replace("youtu.be/", "youtube.com/embed/");
        }
        return link;
    };
    const getVideo = () => {
        const link = getEmbedReadyYTLink(youtubeLink);
        return (
            <iframe
                src={link}
                frameborder="0"
                width="850"
                height="445"
                style={{ paddingLeft: "6vw" }}
            ></iframe>
        );
    };

    const getEditButton = () => {
        return (
            <div
                className="absolute left-[40vw] bottom-[1vw] max-sm:left-[80vw] max-sm:top-[3vw]"
                onClick={() => {
                    navigate(`/edit-blog/${blog_id}`);
                }}
            >
                <img src={editPencil} alt="" />
            </div>
        );
    };

    const getBlogMetaData = () => {
        return (
            <div className="flex items-center">
                <img
                    className="w-[3vw] h-[3vw] max-sm:w-[10vw] max-sm:h-[10vw]"
                    src={author_avatar_url}
                />
                <div className="flex flex-col ml-[1vw]">
                    <p className="font-medium font-PoppinsRegular text-[1vw] max-sm:text-[3vw]">
                        {author}
                    </p>
                    <div className="flex justify-evenly">
                        <p className="text-[#4B5768] max-sm:text-[3vw]">{date_updated}</p>
                        <p className="text-[#4B5768] pl-[2vw] max-sm:text-[3vw]">
                            {" "}
                            {read_time_min} min
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const getShareIcons = () => {
        return (
            <div className="share-icons-container">
                {/* Twitter Share Button (First) */}
                <TwitterShareButton title={ShareMessageContent.MSG_TITLE} url={BLOG_URL} separator={" "}>
                    <div className="share-icon twitter">
                        <img src={twitterIcon} alt="Twitter" />
                    </div>
                </TwitterShareButton>
    
                {/* Facebook Share Button (Second) */}
                <FacebookShareButton title={ShareMessageContent.MSG_TITLE} url={BLOG_URL} separator={" "}>
                    <div className="share-icon facebook">
                        <img src={facebookIcon} alt="Facebook" />
                    </div>
                </FacebookShareButton>
    
                {/* LinkedIn Share Button (Third) */}
                <LinkedinShareButton title={ShareMessageContent.MSG_TITLE} url={BLOG_URL} separator={" "}>
                    <div className="share-icon linkedin">
                        <img src={linkedinIcon} alt="LinkedIn" />
                    </div>
                </LinkedinShareButton>
    
                {/* WhatsApp Share Button (Fourth) */}
                <WhatsappShareButton title={ShareMessageContent.MSG_TITLE} url={BLOG_URL} separator={" "}>
                    <div className="share-icon whatsapp">
                        <img src={whatsappIcon} alt="WhatsApp" />
                    </div>
                </WhatsappShareButton>
            </div>
        );
    };
    
    
    const getBlogContent = () => {
        return (
            <div className="flex flex-col px-[4vw] w-[60%] max-sm:w-[100%] max-sm:px-[0vw] ">
                <div className="relative">{isAdmin && getEditButton()}</div>

                <div className="flex justify-between max-sm:pt-[15vw]">
                    {getBlogMetaData()}
                    {getShareIcons()}
                </div>

                <p className="font-PoppinsSemiBold text-[2vw] py-[2vw] max-sm:text-[4.5vw] max-sm:py-[4vw]">
                    {title}
                </p>
                {getBlogPageImgBanner()}
                {getParsedContents()}
                {youtubeLink ? getVideo() : ""}
            </div>
        );
    };
    return (
        <div className="flex items-start px-[7vw] py-[4vw] max-sm:px-[7vw]">
            {windowWidth > 600 && (
                <div className="w-[20%] sticky top-[8vw]">
                    <TableOfContent />
                </div>
            )}
            {getBlogContent()}

            {windowWidth > 600 && (
                <div className="w-[20%] sticky top-[8vw]">
                    <UpgradeCareer />
                </div>
            )}
        </div>
    );
};

export default BlogContent;
