import "./App.css";
import Header from "./components/Header/Header";
import { GlobalContextProvider } from "./context/GlobalContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import BlogsHomePage from "./components/Home/BlogsHomePage";
import BlogDetails from "./components/BlogPage/BlogDetails";
import AddBlogs from "./components/AdminView/AddBlogs";
import UpdateBlogs from "./components/AdminView/UpdateBlogs";
import TfpRibbon from "./components/TfpRibbon/TfpRibbon";
import { setAuthorizationCookie } from "./cookieManager";

function App() {
    // setAuthorizationCookie(
    //     "eyJraWQiOiJBcXBOQ3NmTmlST2tFUHlIM011THNnSDhpanlhOXFEVlwvcllKbWk4ODZFMD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzNjYzMTJiOS0yNzc5LTQ2MWYtYmVjOC05Zjc4ZTZiOWE2YzUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoLTEuYW1hem9uYXdzLmNvbVwvYXAtc291dGgtMV9pWU9nTFZJUkwiLCJjb2duaXRvOnVzZXJuYW1lIjoiMzY2MzEyYjktMjc3OS00NjFmLWJlYzgtOWY3OGU2YjlhNmM1Iiwib3JpZ2luX2p0aSI6IjEwMzViNGY0LTQ0NTYtNGNhZS04OTkzLWExNDM0MzU4YzFkMiIsImF1ZCI6IjNyMnAxaWM5cWRvZHFnbDJ0YjRyZnB2ZjVrIiwiZXZlbnRfaWQiOiJhZjBhMTI2ZS05YzllLTQ0YjUtYTU0ZC0yNTAyZmRiMTBkODEiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcwNzI5OTU3MCwiZXhwIjoxNzA3Mzg1OTcwLCJpYXQiOjE3MDcyOTk1NzAsImp0aSI6ImM3ZjI4ZTcwLTRjZmItNDIwZC1hZmVmLTk5ZmJmNzMzMjk2YSIsImVtYWlsIjoic2lscGF0bTk3QGdtYWlsLmNvbSJ9.aF9KCYGXhzmURdoUkgicngiHEMQ3TeGXWRtS4sdV2vXRN4JnDu7piylWjOlkINRKTY_5rS3OD_c4gDsx1dhYAE58ri5jrCz6IEmi83RenrOnS3myTel0nyiecr7EwNUZU09FkNuftyi5NSE7Rh8A0_gLLOMlaigvjHYMZeVkjsb2hey4A-wgC1boXumeX938DhGMibMWdxcED6PVwEpQOdQ8J68hkBJb_4X3jWZrHZE64W4LhnTvjPKh9qOY_IGnITX9rHwMRmCSy4KNgRvJl_gRMssLyUL_y3MvZyiODPZnzySC3saOgs41PPsX0pzvzgv8TDVHptBTk9TpmgCA_w"
    // );
    return (
        <div>
            <Router>
                <Header />
                <Routes>
                    <Route exact path="" element={<BlogsHomePage />} />
                    <Route path="/:blog_url/:blog_id" element={<BlogDetails />} />
                    <Route path="/add-blog" element={<AddBlogs />} />
                    <Route path="/edit-blog/:blog_id" element={<UpdateBlogs />} />
                </Routes>

                <Footer />
            </Router>
        </div>
    );
}

export default App;
